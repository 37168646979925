const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});

const joiner = (array) => {
  if (!array.length) return '';
  if (array.length === 1) return array[0];

  const last = array.pop();
  return [array.join(', '), last].join(' & ');
};

const joinVenues = (venues) =>
  joiner(
    venues.length > 1
      ? venues.map((x, i) => x.replace('Gallery ', i > 0 ? '' : 'Galleries '))
      : venues
  );

const formatVenues = (venues = [], showChildren, maxVenues = 10) => {
  try {
    venues = venues.filter(Boolean);

    if (!venues.length) return '';

    const tree = [];

    showChildren = showChildren ?? venues.length <= maxVenues;

    if (!showChildren) venues = venues.filter(({ parent }) => !parent);

    venues
      .sort(({ title: a }, { title: b }) => collator.compare(a, b))
      .sort(({ title }) => {
        if (title === 'Queensland Art Gallery') return -2;
        if (title === 'Gallery of Modern Art') return -1;
      })
      .reduce((map, { ...venue }) => {
        let { expandedTitle, _id, parent } = venue;

        if (expandedTitle) {
          if (!map.has(expandedTitle))
            map.set(expandedTitle, {
              type: 'galleryGroup',
              title: expandedTitle,
              parent
            });

          venue.parent = expandedTitle;
        }

        map.set(_id, venue);

        return map;
      }, new Map())
      .forEach((item, i, map) => {
        item.parent = map.get(item.parent);

        if (item.parent) {
          if (!item.parent?.children) item.parent.children = [];
          item.parent.children.push(item);
        } else {
          tree.push(item);
        }

        return map;
      });

    const reducer = (arr, item) => {
      if (item.children) {
        let children =
          item.children && item.children.reduce(reducer, []).flat();

        if (item.type == 'galleryGroup' && children) {
          arr.push(`${joinVenues(children)} (${item.title})`);
        } else {
          let items = [item.title, children && joiner(children)].filter(
            Boolean
          );

          if (!item.parent) {
            arr.push(items.join(', '));
          } else {
            arr.push(joiner(items));
          }
        }
      } else {
        arr.push(item.title);
      }

      return arr.filter(Boolean);
    };

    return joiner(tree.reduce(reducer, []));
  } catch (e) {
    console.log(e);
    return '';
  }
};

module.exports = {
  joiner,
  joinVenues,
  formatVenues
};
