import { default as searchClient } from './client.js';

import { card } from '../../_includes/_partials/card.js';
import { date } from '../../utils/dates.js';

import * as classes from './resource.module.scss';

import Icon from '../../utils/icon.js';

import closeIconUrl from 'url:../../assets/svgs/close.svg';

const icons = {
  close: Icon('close', undefined, {
    close: closeIconUrl
  })
};

export const init = (Alpine) => {
  Alpine.data('resource', Component);

  function Component(data) {
    return {
      ...data,
      relatedResources: undefined,
      fetchRelatedResources: function ({ count = 2 } = {}) {
        if (!this.$data.relatedResources) {
          this.$data.relatedResources = [];

          searchClient
            .initIndex('stories')
            .search(``, {
              analytics: false,
              hitsPerPage: count,
              advancedSyntax: true,
              filters: `NOT objectID:${this.$data.id}`,
              optionalFilters: this.$data.terms.map(
                ({ attribute, value }) => `${attribute}:${value}`
              ),
              optionalWords: this.$data.title
            })
            .then(({ hits }) => {
              this.$data.relatedResources = hits.map(
                ({ publishedAt, image, lqip, imageCaption, ...hit }) => ({
                  ...hit,
                  _createdAt: new Date(publishedAt * 1000),
                  image: {
                    url: image,
                    metadata: { lqip },
                    caption: imageCaption
                      ? [
                          {
                            _type: 'block',
                            children: [{ _type: 'span', text: imageCaption }]
                          }
                        ]
                      : null
                  }
                })
              );
            });
        }

        return this.$data.relatedResources
      },
      Resource: {
        ':class': classes.resource
      },
      Card: {
        ':class': '`card`',
        'x-html': () => {
          const {
            _createdAt,
            engagementMode,
            title,
            image,
            url,
            summary
          } = this.$data;

          return card({
            pretitle: [
              `<a href="/stories?menu%5BengagementMode%5D=${engagementMode}" x-bind="typeof FacetToggle != 'undefined' ? FacetToggle('engagementMode', '${engagementMode}') : {}">${engagementMode}</a>`,
              `<time>${date(_createdAt, {
                showTime: false,
                showDay: false
              })}</time>`
            ].join(' • '),
            title,
            image,
            url,
            content: [
              summary && `<div class="card__summary truncate">${summary}</div>`,
              `<template x-bind="Tags"></template>`
            ].filter(Boolean).join(' '),
            wrap: false
          }).replace(' crossorigin="anonymous"', '')
        }
      },
      RelatedResources: (params) => {
        const template = `<section>
  <h1>Related Stories</h1>

  <ul class="grid card-grid ${classes.relatedResources}">
    <template x-for="relatedResource in resources">
      <li x-data="resource(relatedResource)" x-bind="Card"></li>
    </template>
  </ul>
</section>`;

        return {
          'x-data': JSON.stringify({ resources: [] }),
          'x-model': 'relatedResources',
          'x-modelable': 'resources',
          'x-if': `resources.length`,
          'x-init'() {
            defaultTemplate.bind(this)(template);
            console.log(this);
            this.fetchRelatedResources(params);
          }
        };
      },
      Tags: () => {
        const template = `<ul class="${classes.tagList}">
  <template x-for="term in terms" :key="term.key">
    <template x-if="term.attribute === '_tags' && term.available !== false">
      <li>
        <a x-bind="Tag"></a>
      </li>
    </template>
  </template>
</ul>`;

        return {
          'x-if': 'terms.length',
          'x-init'() {
            defaultTemplate.bind(this)(template);
          }
        }
      },
      Tag: {
        ':class': `{
          ['${classes.tag}']: true,
          ['${classes.activeTag}']: term.isRefined
        }`,
        '@click': `if(term.refine) { $event.preventDefault(), term.refine() }`,
        ':href': `term.url`,
        ':title': 'term.value',
        'x-html': `\`<span>\${term.value}</span> ${icons.close}\``
      }
    };
  }

  function parseTemplate(string) {
    return new DOMParser().parseFromString(string, 'text/html').body.firstElementChild
  }

  function defaultTemplate(string) {
    if (!this.$el.content.firstElementChild) {
      this.$el.content.replaceChildren(parseTemplate(string))
    }
  }
};