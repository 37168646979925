const storiesShowcase = require('./storiesShowcase.11ty.js');

const { v5: uuid } = require('uuid');

module.exports = function ({
  item,
  title,
  type,
  tag,
  category,
  posts,
  ...data
}) {
  let query, resourceIds, facetFilters;

  if (type === 'posts' && posts) {
    resourceIds = posts.map((id) =>
      uuid(`legacy-blog-post-${id.replace('post#', '')}`, uuid.URL)
    );
  } else {
    query = ((type === 'tag' ? tag : category) || '')
      .replaceAll('Australian Cinématheque', 'Cinema')
      .replaceAll('teaching-and-learning', 'Learning')
      .replaceAll('australian-collection', 'Australian Art Collection')
      .replaceAll(/-/g, ' ')
      .replaceAll(/\b\w/g, (match) => match.toUpperCase());

    let facet;

    switch (query) {
      case 'Cinema':
      case 'Learning':
      case 'Art Conservation':
      case 'Australian Art Collection':
      case 'Collection':
      case 'Queensland Artists':
      case 'Learning':
      case 'Publishing':
      case 'Asia Pacific Triennial':
      case 'QAGOMA Research Library':
      case 'Sculpture Walk':
        facet = '_tags';
        break;

      case 'Vida Lahey Memorial Travelling Scholarship':
        query = 'Scholarships';
        break;

      case 'James Turrell':
      case 'Martin Boyce':
      case 'Joe Furlonger':
      case 'Shigeo Toya':
        break;

      default:
        if (
          item?._type === 'event' &&
          item?.title
            .replaceAll(/[^\w]/g, '')
            .match(new RegExp(query.replaceAll(/[^\w]/g, ''), 'i'))
        ) {
          facet = 'relationships.events';
          query = [item.title, item.subtitle].filter(Boolean).join(': ');
        }
    }

    if (facet) {
      facetFilters = {
        [facet]: [query]
      };

      query = undefined;
    }
  }

  return storiesShowcase({
    ...data,
    title: title.replaceAll('Blog', 'Stories'),
    query: query ? `"${query}"` : null,
    resourceIds,
    facetFilters
  });
};
