const { createClient } = require('@sanity/client');

module.exports = createClient({
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATASET || 'production',
  apiVersion: '2021-10-21',
  useCdn: false,
  useCredentials: true,
  token: false
});
