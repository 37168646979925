import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';

import { client as sanityClient, imageUrlBuilder } from '../utils/sanity/index.js';
import { eventUrl, eventFormatDate } from '../$content/events.js';
import { formatVenues } from '../utils/venues.js';
import { date as formatDate } from '../utils/dates.js';

import { index, configure } from 'instantsearch.js/es/widgets';

import {
  connectSearchBox,
  connectHits,
  connectStats
} from 'instantsearch.js/es/connectors';

let timeKey = 0;
let searchTimeout;

let summaryResults = 1;
let pageResults = 20;

let quickSearch;

const storeUrl = `https://qagoma.store`;
const collectionOnlineUrl = 'https://collection.qagoma.qld.gov.au';

let urlParams = null;

let facets = [];
let allCount = 0;

const indexName = 'QAGOMA_Collection_Online';
const algoliaClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_SEARCH_API_KEY
);

const sanity = sanityClient.withConfig({ useCdn: true });

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0
        }))
      });
    }

    return algoliaClient.search(requests);
  }
};

export const init = (Alpine) => {
  Alpine.data('search', searchComponent);
};

const initResults = () => {
  if (quickSearch) {
    const searchStateEvent = new CustomEvent('setSearchState', {
      detail: false
    });
    quickSearch.dispatchEvent(searchStateEvent);
  }

  allCount = 0;

  facets = {
    exhibitions: {
      label: 'Exhibitions',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'sanity'
    },
    events: {
      label: 'Events',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'sanity'
    },
    cinemaScreenings: {
      label: 'Cinema',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'sanity'
    },
    pages: {
      label: 'Pages',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'algolia'
    },
    collection: {
      label: 'Artists & Artworks',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'algolia'
    },
    stories: {
      label: 'Stories',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'algolia'
    },
    store: {
      label: 'Store',
      noResultsLabel: 'Products',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'algolia'
    },
    mediaReleases: {
      label: 'Media Releases',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'algolia'
    },
    educatorResources: {
      label: 'Educator Resources',
      children: [],
      display: [],
      pages: [[]],
      ready: false,
      type: 'algolia'
    }
  };
};

const applyResults = () => {
  const event = new CustomEvent('updateSanityResults', { detail: facets });
  quickSearch.dispatchEvent(event);

  const countEvent = new CustomEvent('updateAllCount', { detail: allCount });
  quickSearch.dispatchEvent(countEvent);
};

const checkReady = () => {
  let groupCount = 0;
  let readyCount = 0;

  Object.entries(facets).forEach(([key, value]) => {
    groupCount++;
    if (value.ready) readyCount++;
  });

  if (readyCount === groupCount) {
    applyResults();
  }
};

const addObject = (_obj, _group) => {
  if (_group.pages[_group.pages.length - 1].length > pageResults)
    _group.pages.push([]);
  _group.pages[_group.pages.length - 1].push(_obj);
  _group.children.push(_obj);
  allCount++;
};

export const performSanitySearches = async (_sterm) => {
  if (!_sterm) {
    initResults();
    applyResults();
    return;
  }

  const requestTime = (timeKey = Date.now());

  const query = `{
    "matches": *[
      _type == "event" && (
        (!references(*[_type == "metadata.audience" && private == true]._id) && [title, synopsis, introduction, subtitle, summary, content] match "*" + $sterm + "*")
        || references(*[_type == "film" && [title, subtitle, synopsis] match "*"+$sterm+"*"]._id)
      )                 
    ]
  }{
    "matches": *[_id in ^.matches[]._id || (references(^.matches[]._id) && _type == "event" && parent._ref in ^.matches[]._id)]{
      ...,
      "type": coalesce(parent->type, type),
      "end": coalesce(end, dateTime(start) + (films[0]->runtime * 60))
    }{
      ...,
      "end": coalesce(dateTime(end), dateTime(start) + (dateTime(parent->end) - dateTime(parent->start)))
    }
  }.matches[select(
    type in ['exhibition', 'exhibition-touring', 'cinema-program'] => true,
    _type == "event" => defined(end) && end > dateTime(now()),
    true
  )]{
    _id,
    _type,
    type
  }`;

  const params = { sterm: _sterm };
  const results = await sanity.fetch(query, params);

  //Check if this is the latest request before processing results
  if (requestTime >= timeKey) {
    for (let i = 0; i < results.length; i++) {
      const result = results[i];

      switch (result.type) {
        case 'exhibition':
          facet = facets.exhibitions;
          break;

        case 'cinema-screening':
        case 'cinema-program':
          facet = facets.cinemaScreenings;
          break;

        default:
          facet = facets.events;
      }

      addObject({ id: result._id }, facet);
    }

    facets.exhibitions.ready = true;
    facets.events.ready = true;
    facets.cinemaScreenings.ready = true;

    checkReady();
  }
};

export const searchComponent = () => ({
  init() {
    search.start();

    initResults();

    this.$nextTick(() => {
      search.addWidgets([
        quickSearchBox({
          container: document.querySelector('#searchBoxIS')
        }),

        quickHits({
          container: document.querySelector('#hits-collection')
        }),

        quickHitsStats({
          group: 'Collection',
          container: document.querySelector('#stats-collection')
        }),

        index({ indexName: 'qagoma.qld.gov.au' }).addWidgets([
          configure({
            tagFilters: ['-media-release', '-education-resource']
          }),

          quickHitsStats({
            group: 'Pages',
            container: document.querySelector('#stats-pages')
          }),

          quickHitsPages({
            facet: 'pages',
            container: document.querySelector('#hits-pages')
          })
        ]),

        index({ indexName: 'qagoma.qld.gov.au' }).addWidgets([
          configure({
            tagFilters: ['media-release']
          }),

          quickHitsStats({
            group: 'Media Releases',
            container: document.querySelector('#stats-mediaReleases')
          }),

          quickHitsPages({
            facet: 'mediaReleases',
            container: document.querySelector('#hits-mediaReleases')
          })
        ]),

        index({ indexName: 'qagoma.qld.gov.au' }).addWidgets([
          configure({
            tagFilters: ['education-resource']
          }),

          quickHitsStats({
            group: 'Educator Resources',
            container: document.querySelector('#stats-educatorResources')
          }),

          quickHitsPages({
            facet: 'educatorResources',
            container: document.querySelector('#hits-educatorResources')
          })
        ]),

        index({ indexName: 'stories' }).addWidgets([
          quickHitsStats({
            group: 'Stories',
            container: document.querySelector('#stats-stories')
          }),

          quickHitsStories({
            container: document.querySelector('#hits-stories')
          })
        ]),

        index({ indexName: 'shopify_products' }).addWidgets([
          quickHitsStats({
            group: 'Store',
            container: document.querySelector('#stats-store')
          }),

          quickHitsStore({
            container: document.querySelector('#hits-store')
          })
        ])
      ]);

      quickSearch = document.getElementById('quick-search');
    });
  },
  open: false,
  sterm: '',
  resultsReady: false,
  searchPage: false,
  selectedFacet: 'all',
  initialFacet: null,
  facets: [],
  allCount: 0,
  activePage: 1,
  pageCount: 1,
  summaryResults: summaryResults,
  pageResults: pageResults,
  openSearch() {
    this.open = true;
    $nextTick(() => {
      this.$refs.searchbox.focus();
    });
  },
  toggle: {
    ['@click']() {
      this.openSearch();
    }
  },
  updateResults(event) {
    this.facets = event.detail;
    this.trimFacets();
  },
  setActivePage(newPage) {
    this.activePage = newPage;
    this.processFacet(this.activePage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  setFacet(newFacet) {
    //Reset current facet data before switching. For some reason the trim wasn't working on the selected facet if it wasn't on the first page.
    this.activePage = 1;
    this.initialFacet = null;

    if (newFacet == this.selectedFacet && newFacet) {
      this.selectedFacet = 'all';
    } else {
      this.selectedFacet = newFacet;
    }

    if (this.selectedFacet == 'all') {
      this.trimFacets();
      urlParams.delete('facet');
    } else {
      this.processFacet();
      urlParams.set('facet', newFacet);
    }

    window.history.pushState(
      'Search',
      'QAGOMA search',
      location.pathname + '?' + urlParams.toString()
    );
  },
  async trimFacets() {
    this.resultsReady = false;
    this.activePage = 1;

    await Promise.all(
      Object.entries(this.facets).map(async ([key, value]) => {
        this.facets[key].display = [];
        this.facets[key].display = this.facets[key].children.slice(
          0,
          summaryResults
        );
        if (this.facets[key].type == 'sanity') {
          await this.renderSanityObjects(key);
        }
      })
    );

    this.resultsReady = true;
  },
  async processFacet(_page = 1) {
    this.resultsReady = false;

    const start = (_page - 1) * pageResults;
    const end = _page * pageResults;

    this.pageCount = Math.ceil(
      this.facets[this.selectedFacet].children.length / pageResults
    );
    this.facets[this.selectedFacet].display = this.facets[
      this.selectedFacet
    ].children.slice(start, end);
    if (this.facets[this.selectedFacet].type == 'sanity') {
      await this.renderSanityObjects(this.selectedFacet);
    }

    this.resultsReady = true;
  },
  async renderSanityObjects(_facet) {
    if (this.facets[_facet].display.length) {
      const docIds = this.facets[_facet].display.map(({ id }) => id);

      const query = `*[_id in $docIds]{
        ...parent->,
        ...films[0]->,
        ...,
        "type": coalesce(parent->type, type),
        "end": coalesce(end, dateTime(start) + (films[0]->runtime * 60)),
        venues[]->,
        categories[]->,
        audiences[]->
      }{
        ...,
        "end": coalesce(dateTime(end), dateTime(start) + (dateTime(parent->end) - dateTime(parent->start)))
      } | order(dateTime(start) asc)`;

      const params = { docIds };
      const results = await sanity.fetch(query, params);

      this.facets[_facet].display = [];

      await Promise.all(
        results.map(async (result) => {
          if (result.type == 'exhibition') {
            this.facets[_facet].display.push({
              id: result._id,
              title: result.title,
              image: result?.image
                ? imageUrlBuilder(result.image).width(450).height(450).url()
                : '',
              sub: `${eventFormatDate(result)}<br/><strong>${formatVenues(
                result.venues
              )
                .replace(/Gallery of Modern Art/g, 'GOMA')
                .replace(/Queensland Art Gallery/g, 'QAG')}</strong>`,
              group: 'Exhibition',
              target: eventUrl({
                start: result.start,
                type: result.type,
                ...result
              })
            });
            return;
          } else {
            const sub = result.start
              ? `${eventFormatDate(result)}<br/><strong>${formatVenues(
                  result.venues
                )
                  .replace(/Gallery of Modern Art/g, 'GOMA')
                  .replace(/Queensland Art Gallery/g, 'QAG')}</strong>`
              : null;

            const slug = eventUrl(result);

            this.facets[_facet].display.push({
              id: result._id,
              title: `${result.title}${
                result.subtitle
                  ? '<br/><span class="search-hit-subtitle">' +
                    result.subtitle +
                    '</span>'
                  : ''
              }`,
              image: result?.image
                ? imageUrlBuilder(result.image).width(450).height(450).url()
                : '',
              sub,
              group: 'Event',
              detail: result?.categories
                ? result.categories.map((cat) => cat.name).join(', ')
                : '',
              target: slug
            });
            return;
          }
        })
      );
    }

    return;
  },
  triggerSearch() {
    if (search.helper) {
      initResults();
      search.helper.setQuery(this.sterm).search();
      performSanitySearches(this.sterm);
    }
  },
  listen() {
    if (['/search', '/search/'].includes(location.pathname)) {
      urlParams = new URLSearchParams(window.location.search);

      summaryResults = 4;

      if (urlParams.get('facet')) {
        const match = Object.keys(facets).filter(
          (facet) => facet == urlParams.get('facet')
        );
        if (match.length) this.initialFacet = urlParams.get('facet');
      }

      this.sterm = urlParams.get('s');

      this.searchPage = true;
      isSearchPage = true;
      this.open = true;

      setTimeout(() => {
        this.triggerSearch();
      }, 100);
    }

    this.$el.addEventListener('updateSanityResults', (event) => {
      this.updateResults(event);
      if (this.initialFacet) this.setFacet(this.initialFacet);
      if (this.selectedFacet !== 'all') this.processFacet();
    });

    this.$el.addEventListener('updateAllCount', (event) => {
      this.allCount = event.detail;
    });

    this.$el.addEventListener('setSearchState', (event) => {
      this.resultsReady = event?.detail;
    });
  }
});

const truncate = (str, n, useWordBoundary) => {
  if (str.length <= n) {
    return str;
  }
  let subString = str.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' ')).replace(/,\s*$/, '')
      : subString.replace(/,\s*$/, '')) + '&hellip;'
  );
};

// Algolia Instant Search widget - Handle collection results
export const renderQuickHits = (renderOptions) => {
  const { hits } = renderOptions;

  for (let i = 0; i < hits.length; i++) {
    const item = hits[i];

    addObject(
      {
        id: item.objectID,
        title: truncate(item.title, 70, true),
        image: item.image_reference
          ? collectionOnlineUrl + item.image_reference
          : null,
        sub: item.creators,
        group: 'Collection',
        target: `${collectionOnlineUrl}${item.url}`
      },
      facets.collection
    );
  }

  facets.collection.ready = true;
  checkReady();

  return;
};

export const renderQuickHitsPages = (renderOptions) => {
  const { hits, widgetParams } = renderOptions;
  const { facet } = widgetParams;

  for (let i = 0; i < hits.length; i++) {
    const item = hits[i];

    addObject(
      {
        id: item.objectID,
        title: item.title.replace(/ – (Media Release|Education Resource)$/, ''),
        image: item.image,
        group: 'Pages',
        target: item.url
      },
      facets[facet]
    );
  }

  facets[facet].ready = true;
  checkReady();

  return;
};

export const renderQuickHitsStories = (renderOptions) => {
  const { hits } = renderOptions;

  for (let i = 0; i < hits.length; i++) {
    const resource = hits[i];

    addObject(
      {
        id: resource.objectID,
        title: resource.title,
        image: resource.image,
        sub: formatDate(resource.publishedAt, { showTime: false }),
        group: 'Stories',
        target: resource.url
      },
      facets.stories
    );
  }

  facets.stories.ready = true;
  checkReady();

  return;
};

export const renderQuickHitsStore = (renderOptions) => {
  const { hits } = renderOptions;

  for (let i = 0; i < hits.length; i++) {
    const product = hits[i];

    addObject(
      {
        id: product.objectID,
        title: product.title,
        image: product.image,
        sub: `$${product.price}`,
        group: 'Store',
        target: `${storeUrl}/products/${product.handle}`
      },
      facets.store
    );
  }

  facets.store.ready = true;
  checkReady();

  return;
};

const renderQuickSearchBox = (renderOptions, isFirstRender) => {
  const { query, refine, widgetParams } = renderOptions;

  if (isFirstRender) {
    const input = document.createElement('input');
    input.placeholder = 'Search...';
    input.type = 'text';
    input.setAttribute('x-model', 'sterm');
    input.setAttribute('x-ref', 'searchbox');

    input.addEventListener('input', (event) => {
      if (searchTimeout) clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        initResults();
        performSanitySearches(event.target.value);
        refine(event.target.value);
      }, 200);
    });

    widgetParams.container.appendChild(input);
    widgetParams.container.querySelector('input').value = query;
  }
};

export const search = instantsearch({
  indexName,
  searchClient,
  onStateChange({ uiState, setUiState }) {
    initResults();
    setUiState(uiState);
  }
});

const renderQuickHitsStats = (renderOptions, isFirstRender) => {
  const { nbHits } = renderOptions;

  if (isFirstRender) {
    return;
  }

  let count = '';

  if (nbHits > 1) {
    count += `${nbHits} results`;
  } else if (nbHits === 1) {
    count += '1 result';
  } else {
    count = null;
  }

  // if(isSearchPage){
  //   widgetParams.container.innerHTML = count ? `<div class="search-page-group-heading">${widgetParams.group}</div><div class="search-page-group-action">See all ${count}</div>` : '';
  // }else{
  //   widgetParams.container.innerHTML = count ? `${widgetParams.group} (${count})` : '';
  // }
};

export const quickSearchBox = connectSearchBox(renderQuickSearchBox);
export const quickHits = connectHits(renderQuickHits);
export const quickHitsPages = connectHits(renderQuickHitsPages);
export const quickHitsStories = connectHits(renderQuickHitsStories);
export const quickHitsStore = connectHits(renderQuickHitsStore);
export const quickHitsStats = connectStats(renderQuickHitsStats);

export default init;
