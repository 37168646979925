import algoliasearch from 'algoliasearch/lite';
import { createBrowserXhrRequester } from '@algolia/requester-browser-xhr';

export default client = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_STORIES_API_KEY,
  {
    requester: customCreateBrowserXhrRequester(),
    timeouts: {
      connect: 10,
      read: 10
    }
  }
);

function customCreateBrowserXhrRequester() {
  const browserXhrRequester = createBrowserXhrRequester();

  return {
    send: (request) => {
      let url = new URL(request.url);

      request.headers['algolia-host'] = url.hostname;
      request.headers['cache-control'] = 'no-cache';

      url.hostname = location.hostname;
      url.pathname = `/_/algolia${url.pathname}`;

      request.url = url.href;

      return browserXhrRequester.send(request);
    }
  };
}